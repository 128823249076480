import Link from 'next/link'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ICON_PATHS = {
  default: `icon-default.svg`,
  buy: `icon-buy.svg`,
  gift: `icon-gift.svg`,
}

const LinkWrapper = styled.a`
  text-decoration: none;

  > * { /* the button */
    width: 100%;
  }
`

const Wrapper = styled.div`
  border-radius: 25px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  transition:
    background-color 300ms ease,
    box-shadow 300ms ease;

  ${({ cta }) => cta ? 'width: 100%;' : ''}

  ${({ cta, disabled, variant, theme: { colors } }) => {
    if (disabled) {
      if (variant === `destructive`) {
        return `
          background-color: ${colors.primary6};
          box-shadow: none;
        `
      }
      return `
        background-color: ${colors.neutral3};
        box-shadow: none;
      `
    }
    switch (variant) {
      case `quiet`:
        return `
          background-color: ${colors.neutral3};
          border: 1px solid ${colors.neutral1};
          box-shadow: none;
        `
      case `primary`:
        return `
          background-color: ${colors.secondary2};
          box-shadow: inset 0px -2px 0px ${colors.secondary1};
        `
      case `primary-soft`:
        return `
          background-color: ${colors.secondary2};
          box-shadow: inset 0px -2px 0px ${colors.secondary6};
        `
      case `destructive`:
        return `
          background-color: ${colors.destructive1};
          box-shadow: inset 0px -2px 0px ${colors.destructive2};
        `
      default:
        return `
          background-color: ${colors.primary1};
          box-shadow: ${cta ? `inset 0px -2px 0px ${colors.primary7}` : 'none'};
        `
    }
  }};

  &:hover {
    ${({ disabled, variant, theme: { colors } }) => {
      if (!disabled) {
        switch (variant) {
          case `quiet`:
            return `
              background-color: ${colors.neutral8};
            `
          case `primary`:
          case `primary-soft`:
            return `
              background-color: ${colors.secondary5};
            `
          case `destructive`:
            return `
              background-color: ${colors.destructive3};
            `
          default:
            return `
              background-color: ${colors.primary5};
            `
        }
      }
    }}
  }

  &:focus {
    outline: none;
    ${({ disabled, variant, theme: { colors } }) => {
      if (!disabled) {
        switch (variant) {
          case `primary`:
          case `primary-soft`:
            return `
              background-color: ${colors.secondary5};
            `
          case `destructive`:
            return `
              background-color: ${colors.destructive3};
            `
          default:
          // no default
        }
      }
    }}
  }

  &:active {
    ${({ disabled, variant, theme: { colors } }) => {
      if (!disabled) {
        switch (variant) {
          case `quiet`:
            return `
              background-color: ${colors.neutral6};
            `
          case `primary`:
          case `primary-soft`:
            return `
              background-color: ${colors.secondary6};
              box-shadow: none;
            `
          case `destructive`:
            return `
              background-color: ${colors.destructive5};
              box-shadow: none;
            `
          default:
            return `
              background-color: ${colors.primary7};
              box-shadow: none;
            `
        }
      }
    }}
  }

  *:focus {
    outline: none;
  }

  p {
    color: ${({ disabled, variant, theme: { colors } }) => {
      if (disabled) {
        if (variant === `destructive`) {
          return colors.primary3
        }
        return colors.neutral1
      }
      switch (variant) {
        case `quiet`:
          return colors.neutral1
        case `primary`:
        case `primary-soft`:
          return colors.primary1
        case `destructive`:
          return colors.destructive4
        default:
          return 'white'
      }
    }};
  }

  ${props => props.flatSide === `left` && `border-radius: 0 20px 20px 0;`}
  ${props => props.flatSide === `right` && `border-radius: 20px 0 0 20px;`}
`

const Grid = styled.div`
  display: grid;
  ${props =>
    props.icon
      ? `grid-template-columns: 36px 1fr;`
      : `grid-template-columns: 1fr;`}
`

const Icon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(/images/${props => props.fileName});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-self: center;
  justify-self: end;

  ${({ disabled }) => disabled && `
    filter:
      brightness(0)
      saturate(100%)
      invert(83%)
      sepia(14%)
      saturate(295%)
      hue-rotate(5deg)
      brightness(101%)
      contrast(88%);
    `
  }
`

const Label = styled.p`
  ${props => props.theme.fonts.NunitoSans};
  letter-spacing: 0;
  text-align: center;
  line-height: 1rem;
  margin: 0;

  padding: ${({ cta, variant }) => cta || (variant && variant !== `quiet`) ? 15 : 9}px 25px;

  ${({ cta }) => {
    if (cta) {
      return `
        font-size: 1.5rem;
        font-weight: 600;
      `
    }
    return `
      font-size: 1.2rem;
      font-weight: 800;
    `
  }}

  :not(:first-child) {
    padding-left: 12px;
  }
`

const disabledOnClick = event => {
  event.preventDefault()
  event.stopPropagation()
  false
}

const isInternal = href => {
  try {
    const { host: hrefHost } = new URL(href)
    const { location: { host: windowHost } } = window
    return hrefHost === windowHost
  } catch (error) {
    return true
  }
  return false
}

const resolveOnClick = (disabled, givenOnClick, href) => {
  if (disabled) return disabledOnClick
    // disabled trumps all, do nothing when clicked
  if (givenOnClick) return givenOnClick
    // if provided an onClick, use it
  if (!isInternal(href)) return () => window.location.href = href
    // if the href is external, then create an onClick to handle it
  if (!href) return disabledOnClick
    // we don’t have any onClick or linkable values, do nothing when clicked
  return undefined
    // else don’t affect anything
}

const RoundedButton = ({
  as,
  cta,
  disabled,
  flatSide,
  href,
  icon,
  label,
  onClick: givenOnClick,
  variant,
}) => {
  const onClick = resolveOnClick(disabled, givenOnClick, href)
  const button = (
    <Wrapper
      cta={cta}
      disabled={disabled}
      flatSide={flatSide}
      variant={variant}
      onClick={onClick}
    >
      <Grid icon={!!icon}>
        {icon && <Icon disabled={disabled} fileName={ICON_PATHS[icon]} />}
        <Label cta={cta} variant={variant}>{label}</Label>
      </Grid>
    </Wrapper>
  )
  if (onClick) return button
    // we have an action, we’re done here
  if (href) {
    // we have an internal link, we’ll wrap with Link and return it
    return (
      <Link href={href} as={as} passHref>
        <LinkWrapper>{button}</LinkWrapper>
      </Link>
    )
  }
  return button
    // we shouldn‘t end up here...
    // but just in case we return a button that does nothing
}

RoundedButton.propTypes = {
  as: PropTypes.string,
  cta: PropTypes.bool,
  disabled: PropTypes.bool,
  flatSide: PropTypes.oneOf([`left`, `right`]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.oneOf(Object.keys(ICON_PATHS)),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([`quiet`, `primary`, `primary-soft`, `destructive`]),
}

RoundedButton.defaultProps = {
  as: null,
  cta: false,
  disabled: false,
  flatSide: null,
  href: null,
  icon: null,
  onClick: null,
  variant: null,
}

export default RoundedButton
